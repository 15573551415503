* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  scrollbar-width: none;
  overscroll-behavior-x: none;
  background-color: #000000;
  font-family: "Stapel-Light";
  user-select: none;
}

::-webkit-scrollbar {
  display: none;
}

body {
  height: 100%;
  overflow-y: auto;
}

html {
  overflow: hidden;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #ffffff;
}

@font-face {
  font-family: "Stapel-Light";
  src: local("Stapel-Light"), url("../public/fonts/Stapel_Light.otf");
}

@font-face {
  font-family: "Stapel_Expanded_Bold";
  src: local("Stapel_Expanded_Bold"),
    url("../public/fonts/Stapel_Expanded_Bold.otf");
}

@font-face {
  font-family: "Stapel_Bold";
  src: local("Stapel_Bold"), url("../public/fonts/Stapel_Extra_Bold.otf");
}

@font-face {
  font-family: "Stapel_Medium";
  src: local("Stapel_Medium"), url("../public/fonts/Stapel_Medium.otf");
}

@font-face {
  font-family: "Stapel_Semi_Expanded_Bold";
  src: local("Stapel_Semi_Expanded_Bold"),
    url("../public/fonts/Stapel_Semi_Expanded_Bold.otf");
}

@font-face {
  font-family: "Stapel_Condensed_Extra_Bold";
  src: local("Stapel_Condensed_Extra_Bold"),
    url("../public/fonts/Stapel_Condensed_Extra_Bold.otf");
}

button {
  font-family: "Stapel_Bold";
}

a {
  text-decoration: none;
}
